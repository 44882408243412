<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
// .link-table
//   .link-setting
//     opacity: 0
//   &:hover
//     .link-setting
//       opacity: 1
.table-preview
  font-size: 11px
  thead
    tr
      th
        // font-weight: normal
        color: #999

.container-search-pad
  padding-top: 53px

.card-preview
  overflow: hidden
  height: 3rem
  margin: 0
.card-overlay
  height: 3rem
  position: relative
  top: -3rem
  background: rgb(255,255,255)
  // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 2rem,  rgba(255,255,255,0.7) 2.2rem, rgba(255,255,255,0.8) 100%)
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 3.5rem)
  margin-bottom: -4rem
.card-link
  text-decoration: none
  &:hover
    .card-body
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1) !important
    .card-overlay
      background: none !important
.card-body
  .title
    font-size: 1rem
    .sub
      font-size: 11px
      // color: #ccc !important
      // font-weight: normal
.card-link-row
  &:hover
    text-decoration: none
    .card-body
      background-color: #fafafa !important
      .async
        opacity: 1.0
</style>
<template lang="pug">
div
  //- .container-search-pad
  //- div.container-search.bg-white.shadow-sm.pl-2
    form.form-inline
      input.form-control.bg-light.mr-2(type='text' placeholder='고객검색')
      input.form-control.bg-light.mr-2(type='text' placeholder='내용검색')
      button.btn.btn-default.text-primary(type='submit') 검색
  .mb-2
    .p-0
      //- .mb-4
        router-link.sub-link.mr-3(:to='`/property/${property_id}/activity`')
          strong 모든 목록
        //- router-link.sub-link.mr-3(:to='`/property/${property_id}/activity/changelog`')
          strong 변경내역
        //- router-link.sub-link.mr-3(:to='`/property/${property_id}/activity/changelog`')
        //-   strong 변경내역
        //- a.link.mr-3(href='#') 변경내역
      .container.p-4.bg-white.shadow-sm.rounded
        h5.title.pb-4.pt-2 모든 목록
          button.btn.btn-primary.ml-4.float-right(@click='add_document') 새 고객목록 만들기

        //- .mb-3

        .pb-2
          a.mr-2.text-secondary(href='#' @click='switch_order(`record_updated_at`, `DESC`)')
            b-icon-check(v-show='order_by == `record_updated_at`')
            small 최신변경순
          a.mr-2.text-secondary(href='#' @click='switch_order(`created_at`, `ASC`)')
            b-icon-check(v-show='order_by == `created_at` && order == `ASC`')
            small 등록순
          a.mr-2.text-secondary(href='#' @click='switch_order(`created_at`, `DESC`)')
            b-icon-check(v-show='order_by == `created_at` && order == `DESC`')
            small 최신등록순
          a.mr-2.text-secondary(href='#' @click='switch_order(`name`, `ASC`)')
            b-icon-check(v-show='order_by == `name` && order == `ASC`')
            small 가나다순

          //- a.mr-2(href='#' @click='toggle_order(`record_updated_at`)'): small 최근변경순
          //-   b-icon-caret-down-fill(v-show='order_by == `record_updated_at` && order==`ASC`')
          //-   b-icon-caret-up-fill(v-show='order_by == `record_updated_at` && order==`DESC`')
          //- a.mr-2(href='#' @click='toggle_order(`created_at`)'): small 생성일순
          //-   b-icon-caret-down-fill(v-show='order_by == `created_at` && order==`ASC`')
          //-   b-icon-caret-up-fill(v-show='order_by == `created_at` && order==`DESC`')
          //- a.mr-2(href='#' @click='toggle_order(`name`)'): small 가나다순
          //-   b-icon-caret-down-fill(v-show='order_by == `name` && order==`ASC`')
          //-   b-icon-caret-up-fill(v-show='order_by == `name` && order==`DESC`')

        //- .row(style='max-width: 900px')
        .row
          .col-lg-4.col-md-6.col-sm-12.mb-2(v-for='document in documents')
            router-link.card-link(:to='`/property/${property_id}/customer/${document.id}/${$options.filters.encodeText(document.name)}`')
              .shadow-sm.rounded.border.p-3.card-body
                h5.title.text-primary {{document.name}}
                  //- span.float-right.sub.text-muted {{document.created_at | datetime}}
                p.card-preview
                  //- small.text-muted(v-for='col in document.config.cols' v-if='col.position != `hidden`') {{col.label}}
                  small.text-muted.badge.badge-defualt.bg-light.mr-1(v-for='col in document.config.cols' v-if='col.position != `hidden`') {{col.label}}
                  //- ,
                  .card-overlay
                .text-right
                  small.text-muted(v-if='order_by == "record_updated_at"' v-b-tooltip.hover :title='$options.filters.datetime(document.record_updated_at) + " 변경됨"') {{(document.record_updated_at) | fromNow}}
                  small.text-muted(v-else v-b-tooltip.hover :title='$options.filters.datetime(document.created_at) + " 추가됨"') {{(document.created_at) | fromNow}}
                  //- small.text-muted(v-b-tooltip.hover :title='$options.filters.datetime(document.record_updated_at || document.updated_at || document.created_at)') {{(document.record_updated_at || document.updated_at || document.created_at) | fromNow}}
          //- .col-4.mb-2(v-for='document in documents')
            router-link.card-link(:to='`/property/${property_id}/customer/${document.id}`')
              .shadow-sm.rounded.border.p-3.card-body
                h5.title {{document.name}}
                p.card-preview
                  //- small.text-muted(v-for='col in document.config.cols' v-if='col.position != `hidden`') {{col.label}}
                  small.text-muted.badge.badge-defualt.bg-light.mr-1(v-for='col in document.config.cols' v-if='col.position != `hidden`') {{col.label}}
                  //- ,
                  .card-overlay
      .mb-3
      .container.p-4.bg-white.shadow-sm.rounded
        h5.title.pb-4.pt-2 저장된 필터
        .shadow-sm.border-bottom(v-if='rows_listed.length')
          div(v-for='row in rows_listed')
            router-link.card-link-row(:to='`/property/${property_id}/customer/${row.document.id}-${row.id}/${$options.filters.encodeText(row.document.name)}`')
              .rounded-0.border-top.p-3.card-body
                h5.title {{row.name || '(이름없음)'}}
                  small.text-muted.float-right Created at {{row.created_at | datetime}}
                    |  by {{ username(row.user_id) }}
                    button.btn.btn-default.text-muted.rounded-0.p-0(type='button' @click.prevent.stop='delete_filter(row)')
                      b-icon-x
                small.text-muted.bg-light.p-2.rounded.float-right(v-if='row.is_shared != "Y"')
                  span.mr-1(style='position:relative; top: -1px; left: -2px'): b-icon-lock
                  | 비공개
                small.text-primary.bg-light.p-2.rounded.float-right(v-else) 공유됨
                p.card-preview(style='height: 1.5rem')
                  small.text-muted.badge.badge-defualt.bg-light.mr-1 {{row.document.name}}
                  small.text-muted.badge.badge-defualt.bg-light.mr-1(v-for='config in row.search_config') {{config.value}}
        div(v-else)
          strong.text-muted(style='opacity: 0.5') 고객목록에서 조회한 검색 결과를 저장하여 추가합니다.

      //- .bg-white.shadow-sm.mb-2.border(v-for='document in documents')
        .p-3
          router-link.d-block.text-left(:to='`/property/${property_id}/customer/${document.id}`')
            strong {{document.name}}
        table.table.table-preview
          thead
            tr
              th.border-0(v-for='col in document.config.cols' v-if='col.visible_at_table == `Y`') {{col.label}},
        //- button.btn(type='button') 내용보기


  //- .bg-light(style='width: 2000px; height: 100vh')
    p sdfsdf
  //- div.container-left
    .container-left-top.rounded-left.shadow-sm.ml-1
      .row.justify-content-center.align-items-center(style='')
        .col
          .p-4.container-customer
            h5.title
              span {{$store.state.property.name}}
              span.text-white .
            .row.pt-3.async(:class='{done: done}')
              template(v-for='document in documents')
                router-link.btn.btn-sm.btn-block.text-left.link-table(
                  :to='`/property/${property_id}/customer/${document.id}`'
                  :class='[(document.id == document_id ? `btn-light text-primary` : `btn-default`) ]'
                ) {{document.name}}
                  router-link.float-right.text-secondary(v-if='document.id == document_id' :to='`/property/${property_id}/customer/${document.id}/setting`' @click.prevent='') 설정

              .col.text-center.mt-3(style='opacity: .2' v-show='documents.length === 0')
                strong 목록이 없습니다.


            .mb-4
            a.btn.btn-default.text-primary.btn-block(type='button' @click='add_document()' :disabled='adding') 고객목록 추가
              //- b-spinner.ml-4.async(label='가져오는중' small variant='secondary' :class='{done: adding}')
            //- h5 관련 정보
            //- p 관련 정보가 없습니다.

    //- .container-left-bottom.shadow.p-2
      //- a.link.p-3.d-block(href='#') 새 목록 만들기
      //- a.btn.btn-default.text-primary.btn-block 고객목록 추가

  //- div.container-right.bg-white.shadow-sm
    router-view


</template>

<script>

// import jquery from "jquery";
import {keyBy} from "lodash"

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    // documents() {
    //   return this.$store.state.documents
    // },
  },
  data() {
    return {
      done: false,
      adding: false,
      documents: [],
      current_document: {},

      order_by: 'created_at',
      order: 'ASC',

      rows_listed: [],
    }
  },
  async mounted() {
    // this.loaded = true
    this.load()
    // this.done = true
  },
  methods: {
    username(id) {
      return this.$store.state.users_by_id[id]?.name || '(이름없음)'
    },
    async delete_filter(row) {
      if(!confirm('삭제하시겠습니까?')) return false
      try {
        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/documents/saved-filters/${row.id}`)
        if (r?.data?.message != 'ok') throw new Error('삭제 실패')

        this.load()
        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load() {
      try {
        if (window.sessionStorage && window.sessionStorage.__activity_order && window.sessionStorage.__activity_order_by) {
          const order_by = window.sessionStorage.__activity_order_by
          if (['record_updated_at', 'created_at', 'name'].includes(order_by)) {
            this.order_by = order_by
          }
          const order = window.sessionStorage.__activity_order
          if (['ASC', 'DESC'].includes(order)) {
            this.order = order
          }
        }
        // await this.$store.dispatch('documents', this.property_id)
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents`, {
          params: {
            sorts: JSON.stringify([
              {
                k: this.order_by, v: this.order,
              }
            ])
          }
        })
        if (r?.data?.message != 'ok') throw new Error('고객목록 가져오기 실패')
        const rows = r.data.rows

        const document_by_id = keyBy(rows, 'id')
        console.log(document_by_id)

        const r2 = await this.$http.get(`/v1/property/${this.property_id}/views/documents/saved-filters`)
        if (r2?.data?.message != 'ok') throw new Error('저장된 필터목록 가져오기 실패')
        this.rows_listed = r2.data.rows.map(e => {
          e.document = document_by_id[e.document_id]
          return e
        })

        this.documents = rows

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    toggle_order(order_by) {
      const next_value = {'ASC': 'DESC', 'DESC': 'ASC'}

      if (order_by != this.order_by) {
        this.order_by = order_by
      }

      this.order = next_value[this.order]

      if (window.sessionStorage) window.sessionStorage.__activity_order_by = this.order_by
      if (window.sessionStorage) window.sessionStorage.__activity_order = this.order

      this.load()
    },
    switch_order(order_by, order) {
      if (order_by != this.order_by) {
        this.order_by = order_by
      }

      this.order = order

      if (window.sessionStorage) window.sessionStorage.__activity_order_by = this.order_by
      if (window.sessionStorage) window.sessionStorage.__activity_order = this.order

      this.load()
    },
    async add_document() {
      try {
        this.adding = true
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객목록 추가 실패')

        await this.$store.dispatch('documents', this.property_id)

        setTimeout(() => {
          this.adding = false

          this.$router.push({
            path: `/property/${this.property_id}/customer/${r.data.document_id}/${this.$options.filters.encodeText(r.data.name)}`
          })

        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    open_menu(event, document) {
      // todo: contextmenu

      this.current_document = Object.assign({}, document)

      this.$modal.show('contextmenu', {
        params: {
          clientX: event.clientX,
          clientY: event.clientY,
        }
      })

      setTimeout(() => {
        const $input = this.$el.querySelector('#customer-contextmenu input[autofocus]')
        if ($input) $input.focus()
      }, 300);
    }
  },
}
</script>
